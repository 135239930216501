import revive_payload_client_YTGlmQWWT5 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3ZcyKDgODd from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_2yIKADAg6h from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_zl2pp1dYXk from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import openreplay_client_x9gNryAGwo from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/openreplay.client.ts";
import gtag_bAM3HbiZET from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/gtag.js";
import apexchart_client_5d6ra58DX6 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/apexchart.client.ts";
import api_GFfDXud5Cr from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/api.ts";
import dompurify_html_VcfsMfUvBB from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/dompurify-html.ts";
import gmap_pjGla1KF16 from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/gmap.ts";
import notify_client_jkCTjllQJP from "/runner/_work/merchant-dashboard-revamp/merchant-dashboard-revamp/packages/merchant-dashboard/plugins/notify.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_3ZcyKDgODd,
  plugin_2yIKADAg6h,
  plugin_zl2pp1dYXk,
  openreplay_client_x9gNryAGwo,
  gtag_bAM3HbiZET,
  apexchart_client_5d6ra58DX6,
  api_GFfDXud5Cr,
  dompurify_html_VcfsMfUvBB,
  gmap_pjGla1KF16,
  notify_client_jkCTjllQJP
]