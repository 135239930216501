<script setup>
import Components from "@/containers/auth/set-password";

definePageMeta({
  layout: "auth",
  middleware: [
    () => {
      const token = useCookie("token"); // get token from cookies
      if (token.value) {
        return navigateTo("/");
      }
    },
  ],
});
</script>
<template>
  <Components />
</template>
