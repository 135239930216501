<script setup>
import LandingPage from "@/containers/landingPage";

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
  name: "LandingPageBuilder",
});
useHead({
  title: `Landing Page Builder`,
});
</script>
<template>
  <LandingPage />
</template>
