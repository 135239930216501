import type { FetchOptions } from "ofetch";
import FetchFactory from "../factory";
import { useAuthStore } from "~/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class OrderFormModule extends FetchFactory<IResponse> {
  async getForms(query) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await this.call(
        "GET",
        `${config.ORDERFORMSVC}/v1/seller-dash/order-form?${query}`,
        undefined,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async getBySlug({ slug, status }: { slug: string; status: string }) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await this.call(
        "GET",
        `${config.ORDERFORMSVC}/v1/seller-dash/order-form/${slug}?status=${status}`,
        undefined,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async saveForm(payload: Object) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await this.call(
        "POST",
        `${config.ORDERFORMSVC}/v1/seller-dash/order-form`,
        payload,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async validateSlug(slug: string) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await this.call(
        "GET",
        `${config.ORDERFORMSVC}/v1/seller-dash/order-form/${slug}/exists`,
        undefined,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async deleteForm(slug: string) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await this.call(
        "DELETE",
        `${config.ORDERFORMSVC}/v1/seller-dash/order-form/${slug}`,
        undefined,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async getLeads(query: Object) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      query,
    };
    try {
      return await this.call(
        "GET",
        `${config.ORDERFORMSVC}/v1/seller-dash/leads`,
        undefined,
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }

  async changeLeadStatus(leadId: string, statusId: number) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await this.call(
        "PUT",
        `${config.ORDERFORMSVC}/v1/seller-dash/leads/${leadId}/status`,
        {
          lead_status_id: statusId,
        },
        fetchOptions,
      );
    } catch (err) {
      return err;
    }
  }
}

export default OrderFormModule;
