<script setup>
import Help from "@/containers/analytics/checkout";
definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});

useHead({
  title: "Analisis Checkout",
});
</script>
<template>
  <Help />
</template>
