<script setup>
import CustomerReport from "@/containers/report/customer";

useHead({
  title: "Customer Report",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <CustomerReport />
  </div>
</template>
