<script setup>
import OrderForm from "@/containers/orderForm";

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
  name: "ListLandingPage",
});
useHead({
  title: `Atur Landing Page`,
});
</script>
<template>
  <OrderForm />
</template>
