<script setup>
import WarehouseEdit from "@/containers/configurations/warehouse/edit";

useHead({
  title: "Ubah Warehouse",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <WarehouseEdit />
  </div>
</template>
