<script setup>
import Invoice from "@/containers/manualOrder/invoiceLink/invoice";

useHead({
  title: "Create Invoice Link",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <Invoice />
  </div>
</template>
