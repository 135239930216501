<script setup>
import Content from "@/containers/orderForm/leads";

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
  name: "ListLeadsManagement",
});
useHead({
  title: `List of Leads Management`,
});
</script>
<template>
  <Content />
</template>
