<script setup>
import EditPromotions from "@/containers/promotions/detail";

useHead({
  title: "Ubah Promotions",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <EditPromotions />
  </div>
</template>
