<script setup>
import ForgotPassword from "@/containers/auth/forgotPassword";

definePageMeta({
  layout: "auth",
});
</script>

<template>
  <ForgotPassword />
</template>
