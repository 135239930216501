<script setup>
import Settlement from "@/containers/report/settlement";

useHead({
  title: "Settlement Report",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <Settlement />
  </div>
</template>
