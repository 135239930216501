import { useCookie, useRuntimeConfig } from "nuxt/app";
import { defineStore } from "pinia";

interface UserPayloadInterface {
  email: string;
  password: string;
}

interface VerifyUserPayload {
  access_token: string;
  refresh_token: string;
}

interface OTPPayload {
  phone: string;
  device_id: string;
  channel: string;
  purpose: string;
  data?: Record<string, any>;
}
interface RegisterPayload {
  brand_name: string;
  full_name: string;
  phone: string;
  email: string;
  password: string;
  otp: string;
}

interface AuthStore {
  authenticated: boolean;
  user: any;
  loading: boolean;
  expired_otp: number;
}

export const useAuthStore = defineStore({
  id: "auth",
  state: () =>
    ({
      authenticated: false,
      user: undefined,
      loading: false,
      expired_otp: 0,
    }) as AuthStore,
  actions: {
    async login({ email, password }: UserPayloadInterface) {
      const api_url = useRuntimeConfig().public.API_URL;
      const payload = {
        email,
        password,
      };
      // useFetch from nuxt 3
      const { status, data, error } = await $fetch(
        "/auth/v1/authentication/merchant-user/login",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const decodeJWT = JSON.parse(
          atob(data.access_token.split(".")[1]).toString(),
        );
        const token = {
          atoken: data.access_token,
          rtoken: data.refresh_token,
          merchant_login_id: decodeJWT.sub,
          email: decodeJWT.email,
        };
        const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
        tokenCookies.value = data.access_token; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        this.user = token; // set authenticated  state value to true
      }
      return { status, data, error };
    },
    async registerMerchant(payload: RegisterPayload) {
      const api_url = useRuntimeConfig().public.API_URL;
      // useFetch from nuxt 3
      const { status, data, error } = await $fetch(
        `/merchant/v2/merchant-user/reg`,
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const decodeJWT = JSON.parse(
          atob(data.access_token.split(".")[1]).toString(),
        );
        const token = {
          atoken: data.access_token,
          rtoken: data.refresh_token,
          merchant_login_id: decodeJWT.sub,
          email: decodeJWT.email,
        };
        const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
        tokenCookies.value = data.access_token; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        this.user = token; // set authenticated  state value to true
      }
      return { status, data, error };
    },
    verify({ access_token, refresh_token }: VerifyUserPayload) {
      const decodeJWT = JSON.parse(atob(access_token.split(".")[1]).toString());
      const token = {
        atoken: access_token,
        rtoken: refresh_token,
        merchant_login_id: decodeJWT.sub,
        email: decodeJWT.email,
      };
      const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
      tokenCookies.value = access_token; // set token to cookie
      this.authenticated = true; // set authenticated  state value to true
      this.user = token; // set authenticated  state value to true
    },
    async refresh(refresh_token: string) {
      const api_url = useRuntimeConfig().public.API_URL;
      const payload = {
        refresh_token: refresh_token,
      };
      // useFetch from nuxt 3
      const { status, data, error } = await $fetch(
        "/auth/v1/authentication/merchant-user/refresh",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const decodeJWT = JSON.parse(
          atob(data.access_token.split(".")[1]).toString(),
        );
        const token = {
          atoken: data.access_token,
          rtoken: data.refresh_token,
          merchant_login_id: decodeJWT.sub,
          email: decodeJWT.email,
        };
        const tokenCookies = useCookie("token"); // useCookie new hook in nuxt 3
        tokenCookies.value = data.access_token; // set token to cookie
        this.authenticated = true; // set authenticated  state value to true
        this.user = token; // set authenticated  state value to true
      }
      return { status, data, error };
    },
    logout() {
      const token = useCookie("token"); // useCookie new hook in nuxt 3
      this.authenticated = false; // set authenticated  state value to false
      token.value = null; // clear the token cookie
      this.user = undefined; // clear the token cookie
    },
    async sendOTP(payload: OTPPayload) {
      const api_url = useRuntimeConfig().public.API_URL;
      // useFetch from nuxt 3
      const { status, data, errors } = await $fetch<any>(
        "/auth/v1/authentication/otp/send",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: { "Content-Type": "application/json" },
        },
      ).catch((err) => err);

      if (status === "success") {
        const expires_at = data.expires_at;
        this.expired_otp = expires_at * 1000 - Date.now();
      }

      return { status, data, error: errors ? errors[0] : null };
    },
    async sendOTPForMerchantVerifyEmail(payload: OTPPayload) {
      const api_url = useRuntimeConfig().public.API_URL;
      const token = this.user.atoken;
      const { data, status } = await $fetch<any>(
        "/auth/v1/seller-dash/merchant/otp/send",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      ).catch((err) => err);

      if (status === "success") {
        const expires_at = new Date(data.expires_at).getTime();
        this.expired_otp = expires_at - Date.now();
      }

      return { error: data.errors ? data.errors[0] : null, status, data };
    },
    updateExpired(value: number) {
      this.expired_otp = value;
    },
    async sendForgotPasswordEmail(payload: { email: string }) {
      const api_url = useRuntimeConfig().public.API_URL;
      const { data, status, errors } = await $fetch<any>(
        "/merchant/v1/merchant-user/send-forgot-password",
        {
          baseURL: api_url,
          method: "POST",
          body: payload,
          headers: {
            "Content-Type": "application/json",
          },
        },
      ).catch((err) => err);

      return { error: errors ? errors[0] : null, status, data };
    },
  },
  persist: true,
});
