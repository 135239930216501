<script setup>
import Warehouse from "@/containers/configurations/warehouse";

useHead({
  title: "Pengaturan Warehouse",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <Warehouse />
  </div>
</template>
