<script setup>
import WarehouseAdd from "@/containers/configurations/warehouse/add";

useHead({
  title: "Buat Warehouse Baru",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <WarehouseAdd />
  </div>
</template>
