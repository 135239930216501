// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class LeadModule extends FetchFactory<IResponse> {
  getList(payload) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads?${payload}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getHistory(payload: { id: string; query: string }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${payload.id}/orders?${payload.query}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getListStatus() {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/status`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getListOrderForm() {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/order-form`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getListSales() {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/sales`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getListCity(key) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/cities?search=${key}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getListRates(payload: {
    origin_postal_code: string;
    destination_postal_code: string;
    weight: number;
  }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${ORDERFORMSVC}/v1/seller-dash/leads/check/rates`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  getDetail(id) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${id}`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  getProduct(id) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${id}/product`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  createInvoice(payload: {
    id?: string;
    item_price: number;
    additional_price: number;
  }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const id = payload.id;
    delete payload.id;
    return this.call(
      "POST",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${id}/invoice`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  updateSalesLead(payload: { id: string; sales_id: string }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${payload.id}/sales`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  updateStatusLead(payload: { id: string; lead_status_id: number }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${payload.id}/status`,
      payload,
      fetchOptions,
    ).catch((err) => err);
  }

  validateCreateInvoice(id: string) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${id}/validate/last_order`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  exportLeadsReport(query) {
    const { REPORTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      query,
    };
    return this.call(
      "GET",
      `${REPORTSVC}/v1/seller-dash/leads/report`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }

  cancelInvoice(payload: { id: string; order_id: number }) {
    const { ORDERFORMSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return this.call(
      "PUT",
      `${ORDERFORMSVC}/v1/seller-dash/leads/${payload.id}/orders/${payload.order_id}/cancel`,
      undefined,
      fetchOptions,
    ).catch((err) => err);
  }
}

export default LeadModule;
