<script setup>
import Step4 from "@/containers/onboarding/step-4";

definePageMeta({
  layout: "onboarding",
  middleware: ["auth", "onboarding"],
});
</script>
<template>
  <Step4 />
</template>
