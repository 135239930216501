<script setup>
import DetailCustomer from "@/containers/report/customer/detail";

useHead({
  title: "Customer Report Detail",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <DetailCustomer />
  </div>
</template>
