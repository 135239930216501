<script setup>
import Pengirim from "@/containers/manualOrder/pengirim/step-1";

definePageMeta({
  layout: "dashboard",
  middleware: ["auth"],
});
</script>
<template>
  <Pengirim />
</template>
