<script setup>
import Detail from "@/containers/help/detail";
definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});

useHead({
  title: "Bantuan",
});
</script>
<template>
  <Detail />
</template>
