<script setup>
import Payment from "@/containers/configurations/payment";

useHead({
  title: "Payment Configurations",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <Payment />
  </div>
</template>
