<script setup>
import Step2 from "@/containers/completing/step-2";

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "onboarding"],
});
</script>
<template>
  <Step2 />
</template>
