/* eslint-disable no-useless-escape */
import { uuid } from "vue3-uuid";
import { useMerchantStore } from "@/stores/merchant";

export const serialize = (obj: { [key: string]: string }) => {
  const str: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  });
  return str.join("&");
};

export const serializeQuery = (obj: { [key: string]: string }) => {
  const str: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      str.push(
        `queries[${encodeURIComponent(key)}]=${encodeURIComponent(obj[key])}`,
      );
    }
  });
  return str.join("&");
};

export const formatRupiah = (money: number) => {
  return new Intl.NumberFormat("en-ID", {
    minimumFractionDigits: 0,
  }).format(money);
};

export const validSHA256 = (str: string) => {
  // Regular expression to check if string is a SHA256 hash
  const regexExp = /^[a-f0-9]{128}$/gi;

  return regexExp.test(str);
};

export const numberOnly = (e: any) => {
  // eslint-disable-next-line prefer-regex-literals
  if (!RegExp(/^([0-9]*)$/).test(e.key) && !e.metaKey) e.preventDefault();
};

export const stringOnly = (event: KeyboardEvent) => {
  if (!/[a-zA-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};

export const fileSize = (size: number) => {
  const i: number =
    size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    Number((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const objEmpty = (obj: Object) => {
  if (
    obj || // 👈 null and undefined check
    Object.keys(obj).length === 0 || // 👈 property check
    Object.getPrototypeOf(obj) === Object.prototype
  ) {
    return true;
  } else {
    return false;
  }
};

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
};
export const profile = () => {
  return useMerchantStore().profile;
};
export const showingRecord = (currentPage: number, totalRecord: number) => {
  const endIndex = currentPage * 10;
  const startIndex = (currentPage - 1) * 10 + 1;
  return totalRecord > 0
    ? `${startIndex} - ${endIndex > totalRecord ? totalRecord : endIndex}`
    : "0";
};
export const numberFormat = (value: string) => {
  return Number(String(value).replaceAll(".", ""))
    .toLocaleString()
    .replaceAll(",", ".");
};
export const capitalize = (str: string) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};
export const formatNpwp = (value) => {
  value = value.replace(/[A-Za-z\W\s_]+/g, "");
  let split = 6;
  const dots = [];

  for (let i = 0, len = value.length; i < len; i += split) {
    split = i >= 2 && i <= 6 ? 3 : i >= 8 && i <= 12 ? 4 : 2;
    dots.push(value.substr(i, split));
  }

  const temp = dots.join(".");
  return temp.length > 12
    ? `${temp.substr(0, 12)}-${temp.substr(12, 7)}`
    : temp;
};

export const generateUUID = () => {
  return uuid.v4();
};

export const validUUID = (str) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  const valid = regexExp.test(str);
  return valid;
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );
};

export const debouncer = <T>(
  cb: (...args: any) => Promise<T>,
  timeout: number
) => {
  let timer: any;
  return function (...args: any[]) {
    if (timer) {
      clearTimeout(timer);
    }

    return new Promise<T>((resolve) => {
      timer = setTimeout(async () => {
        // eslint-disable-next-line n/no-callback-literal
        resolve(await cb(...args));
      }, timeout);
    });
  };
};
export const formatAccountNumber = (balance: string) => {
  const visibleDigits = balance.slice(0, 4);
  const maskedLength = balance.length - 4;
  const maskedDigits = "*".repeat(Math.max(maskedLength, 0));
  return `${visibleDigits} ${maskedDigits}`;
};

export const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const currencyStringToInt = (value) => {
  return parseInt(value?.replace("Rp", "").replace(/\./g, ""));
};

export const formatCurrencyAndColor = (value) => {
  const formattedValue = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(Math.abs(value))
    .replace("Rp ", "Rp");

  const finalValue = formattedValue.replace(/\s+/g, "");

  if (value < 0) {
    return {
      text: `-${finalValue}`,
      colorClass: "text-red-500",
    };
  } else {
    return {
      text: `+${finalValue}`,
      colorClass: "text-green-500",
    };
  }
};

export const formatDate = (dateStr) => {
  const backendDate = new Date(dateStr);
  const year = backendDate.getFullYear().toString().substr(-2);
  const month = (backendDate.getMonth() + 1).toString().padStart(2, "0");
  const day = backendDate.getDate().toString().padStart(2, "0");
  const hours = backendDate.getHours() % 12 || 12;
  const minutes = backendDate.getMinutes().toString().padStart(2, "0");
  const ampm = backendDate.getHours() < 12 ? "AM" : "PM";

  return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
};

export const forceResetFG = () => {
  document.querySelectorAll(".tg-backdrop").forEach((e) => e.remove());
  document.querySelectorAll(".tg-dialog").forEach((e) => e.remove());
};

export const getClassBadge = (status: string) => {
  const statusColorMap = new Map([
    ["CREATED", "blue"],
    ["PAID", "blue"],
    ["SCHEDULED", "blue"],
    ["CONFIRMED", "green"],
    ["COURIER_NOT_FOUND", "yellow"],
    ["ALLOCATED", "blue"],
    ["PICKING_UP", "blue"],
    ["ON_HOLD", "yellow"],
    ["PICKUP_FAILED", "yellow"],
    ["DELIVERY_FAILED", "yellow"],
    ["CANCELLED", "red"],
    ["PICKED_UP", "yellow"],
    ["ON_SHIPPING", "blue"],
    ["DELIVERED", "green"],
    ["REJECTED", "red"],
    ["DISPOSED", "red"],
    ["RETURN_IN_TRANSIT", "blue"],
    ["RETURNED", "yellow"],
    ["UNEXPECTED_FAILURE", "red"],
    ["DONE", "green"],
    ["DISBURSED", "green"],
  ]);

  return `bg-${statusColorMap.get(
    status.toUpperCase()
  )}-100 text-${statusColorMap.get(
    status.toUpperCase()
  )}-800 px-3 rounded text-xs text-center py-1`;
};

export const statusEN_IDMap = (status) => {
  const statusMap = new Map([
    ["CREATED", "Menunggu Pembayaran"],
    ["PAID", "Dibayar"],
    ["SCHEDULED", "Dijadwalkan"],
    ["CONFIRMED", "Dikonfirmasi"],
    ["COURIER_NOT_FOUND", "Kurir Tidak Ditemukan"],
    ["ALLOCATED", "Dialokasikan"],
    ["PICKING_UP", "Dalam Penjemputan"],
    ["ON_HOLD", "Tertahan"],
    ["PICKUP_FAILED", "Tertahan"],
    ["DELIVERY_FAILED", "Tertahan"],
    ["CANCELLED", "Dibatalkan"],
    ["PICKED_UP", "Berhasil Dijemput"],
    ["ON_SHIPPING", "Sedang Diantar"],
    ["DELIVERED", "Terkirim"],
    ["REJECTED", "Ditolak"],
    ["DISPOSED", "Disposed"],
    ["RETURN_IN_TRANSIT", "Dalam Transit Pengembalian"],
    ["RETURNED", "Dikembalikan"],
    ["UNEXPECTED_FAILURE", "Terjadi Kesalahan"],
    ["DONE", "Selesai"],
    ["DISBURSED", "Dicairkan"],
  ]);
  return statusMap.has(status.toUpperCase())
    ? statusMap.get(status.toUpperCase())
    : status;
};

export const toIsoLocalTime = (value: Date) => {
  if (value instanceof Date === false) value = new Date();
  const off = value.getTimezoneOffset() * -1;
  const del = value.getMilliseconds() ? "Z" : "."; // have milliseconds ?
  value = new Date(value.getTime() + off * 60000); // add or subtract time zone
  return (
    value.toISOString().split(del)[0] +
    (off < 0 ? "-" : "+") +
    ("0" + Math.abs(Math.floor(off / 60))).slice(-2) +
    ":" +
    ("0" + Math.abs(off % 60)).slice(-2)
  );
};

export const formatAreaPhone = (
  countryCode: string,
  localNumber: string,
  plusSymbol = true
): string => {
  localNumber = localNumber.replace(/\D/g, "");
  localNumber = localNumber.replace(/^0+/, "");

  // Remove the country code from the local number if it's included
  if (localNumber.startsWith(countryCode)) {
    localNumber = localNumber.slice(countryCode.length);
  }
  if (!plusSymbol) {
    return `${countryCode}${localNumber}`;
  }
  const groupedNumber = localNumber.match(/.{1,4}/g)?.join(" ") ?? "";

  // Format the full phone number
  const formattedNumber = `+${countryCode} ${groupedNumber}`;

  return formattedNumber;
};
