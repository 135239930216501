<script setup>
import ManualOrder from "@/containers/manualOrder/invoiceLink";

useHead({
  title: "Manual Order",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <ManualOrder />
  </div>
</template>
