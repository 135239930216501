<script setup>
import Promotions from "@/containers/promotions";

useHead({
  title: "Promosi",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <Promotions />
  </div>
</template>
