<script setup>
import Shipping from "@/containers/configurations/shipping";

useHead({
  title: "Shipping Configurations",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <Shipping />
  </div>
</template>
