<script setup>
import Login from "@/containers/auth/login";

definePageMeta({
  layout: "auth",
  middleware: ["auth"],
});
</script>
<template>
  <Login />
</template>
