<script setup>
import Step1 from "@/containers/completing/step-1";

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "onboarding"],
});
</script>
<template>
  <Step1 />
</template>
