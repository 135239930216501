<script setup>
import Content from "@/containers/orderForm/leads/detail";

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
  name: "DetailLeadsManagement",
});
useHead({
  title: `Detail Leads Management`,
});
</script>
<template>
  <Content />
</template>
