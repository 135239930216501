<script setup>
import EmailVerification from "@/containers/auth/emailverification";
definePageMeta({
  layout: "auth",
  name: "EmailVerification",
});
useHead({
  title: `Email Verification`,
});
</script>
<template>
  <EmailVerification />
</template>
