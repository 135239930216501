<script setup>
import ShippingReport from "@/containers/report/shipping";

useHead({
  title: "Laporan Pengiriman",
});

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});
</script>
<template>
  <div>
    <ShippingReport />
  </div>
</template>
