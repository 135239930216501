<script setup>
import ContactUs from "@/containers/help/contactUs";

definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});

useHead({
  title: "Tanya Kami",
});
</script>
<template>
  <ContactUs />
</template>
