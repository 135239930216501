<script setup>
import Step2 from "@/containers/onboarding/step-2";

definePageMeta({
  layout: "onboarding",
  middleware: ["auth", "onboarding"],
});
</script>
<template>
  <Step2 />
</template>
