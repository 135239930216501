<script lang="ts">
import Register from "@/containers/auth/register";

export default {
  components: {
    Register,
  },
  setup() {
    definePageMeta({
      layout: "auth",
    });
  },
};
</script>
<template>
  <Register />
</template>
