<script setup>
import Help from "@/containers/analytics/shipping";
definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});

useHead({
  title: "Analisis Pengiriman",
});
</script>
<template>
  <Help />
</template>
