<script lang="ts">
import { useRoute } from "vue-router";
import { useNuxtApp, useHead } from "#app";
import { useAuthStore } from "@/stores/auth";

export default {
  async setup() {
    const { $api: api } = useNuxtApp();
    const { verify } = useAuthStore(); // use auth store

    useHead({
      title: `Validation Account`,
    });

    definePageMeta({
      auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: "/",
      },
    });

    const route = useRoute();

    const valid = validSHA256(route.params?.key);

    if (!valid) {
      throw showError({
        statusCode: 400,
        fatal: true,
        message: "Validation key invalid",
      });
    }

    const { data: result, status } = await api.merchant.verifyMerchant(
      route.params?.key,
    );
    if (status === "success") {
      const payload = {
        access_token: result.access_token,
        refresh_token: result.refresh_token,
      };
      await verify(payload);
      const router = useRouter();
      console.log("router", router);
      router.push("/");
    }
  },
};
</script>
<template>
  <div class="flex h-screen w-screen flex-col items-center justify-center">
    <Spinner class="h-12 w-12 fill-primary text-gray-200" />
    <!-- <span class="mt-5 text-3xl font-bold">Please Wait</span> -->
    <p class="mt-5 text-gray-500">Validating Your Account...</p>
  </div>
</template>
