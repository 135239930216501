<script setup>
import Step3 from "@/containers/onboarding/step-3";

definePageMeta({
  layout: "onboarding",
  middleware: ["auth", "onboarding"],
});
</script>
<template>
  <Step3 />
</template>
