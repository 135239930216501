<script setup>
import Pengirim from "@/containers/manualOrder/pengirim/step-3";

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "manual-shipping"],
});
</script>
<template>
  <Pengirim />
</template>
