// 3rd's
import { type FetchOptions } from "ofetch";
// locals
import FetchFactory from "../factory";

import { useAuthStore } from "@/stores/auth";

type IResponse = {
  status: String;
  data: Object;
  errors: [];
};

class MerchantModule extends FetchFactory<IResponse> {
  // private RESOURCE = '/products';

  /**
   * Return the products as array
   * @param asyncDataOptions options for `useAsyncData`
   * @returns
   */
  getProfile() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/dashboard/profile`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  updateProfile(payload: Record<string, any>) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${config.MERCHANTSVC}/v1/dashboard/merchant`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  getOnboarding() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/merchant-user/reg-2`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  setOnboarding(payload: Record<string, any>) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${config.MERCHANTSVC}/v1/merchant-user/reg-2`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  verifyMerchant(key: string) {
    const config = useRuntimeConfig().public;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        vkey: key,
      },
    };

    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v2/merchant-user/reg/verify`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  setFBAds(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${config.MERCHANTSVC}/v1/dashboard/merchant`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  getCouriers(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.SHIPPINGSVC}/v1/merchant_dashboard/couriers`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  updateCustom(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.SHIPPINGSVC}/v1/merchant_dashboard/custom_courier`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  updateCouriers(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.SHIPPINGSVC}/v1/merchant_dashboard/couriers`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  acceptAgreement() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PATCH",
      `${config.MERCHANTSVC}/v1/dashboard/kyc-final`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  updateEmail(payload: Record<string, any>) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.MERCHANTSVC}/v1/merchant-user/reg-2/change-email`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  verifyMerchantUserEmail(payload: Record<string, any>) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.MERCHANTSVC}/v2/merchant-user/reg/verify`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  verifyEmailDuplicate(email) {
    const config = useRuntimeConfig().public;
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/merchant-user/reg/check-email?email=${email}`,
      undefined,
      undefined
    ).catch((err) => err);
  }

  verifyBrandNameDuplicate(name) {
    const config = useRuntimeConfig().public;
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v1/merchant-user/reg/check-brandname?name=${name}`,
      undefined,
      undefined
    ).catch((err) => err);
  }

  postHelpQuestion(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.MERCHANTSVC}/v1/dashboard/support`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  getSummaryData() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v2/homepage/summary`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  updateCheckoutClose() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "PUT",
      `${config.MERCHANTSVC}/v2/homepage/summary/checkout_offer/close`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  getSummaryDataCheckout() {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${config.MERCHANTSVC}/v2/homepage/summary/checkout_offer`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  postBanks(payload) {
    const config = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${config.MERCHANTSVC}/v1/dashboard/banks`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  getCoachmarkByPage(page) {
    const { MERCHANTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "GET",
      `${MERCHANTSVC}/v1/dashboard/coachmarks/get?page=${page}`,
      undefined,
      fetchOptions
    ).catch((err) => err);
  }

  setCoachmark(payload) {
    const { MERCHANTSVC } = useRuntimeConfig().public;
    const { user } = useAuthStore();
    const token = user?.atoken;
    const fetchOptions: FetchOptions<"json"> = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return this.call(
      "POST",
      `${MERCHANTSVC}/v1/dashboard/coachmarks/set`,
      payload,
      fetchOptions
    ).catch((err) => err);
  }

  getMerchantById(id: string) {
    const { MERCHANTSVC } = useRuntimeConfig().public;

    return this.call(
      "GET",
      `${MERCHANTSVC}/v1/merchant/${id}`,
      undefined,
      undefined,
    ).catch((err) => err);
  }

  setPassword(payload: { password: string; token: string }) {
    const { MERCHANTSVC } = useRuntimeConfig().public;

    return this.call(
      "POST",
      `${MERCHANTSVC}/v1/merchant-user/password`,
      payload,
      undefined
    ).catch((err) => err);
  }

  checkTokenSetPassword(payload: { token: string }) {
    const { MERCHANTSVC } = useRuntimeConfig().public;

    return this.call(
      "POST",
      `${MERCHANTSVC}/v1/merchant-user/token/check`,
      payload,
      undefined,
    ).catch((err) => err);
  }
}

export default MerchantModule;
