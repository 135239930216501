<script setup>
import Pengirim from "@/containers/manualOrder/pengirim/success";

definePageMeta({
  layout: "success",
  middleware: ["auth"],
});
</script>
<template>
  <Pengirim />
</template>
