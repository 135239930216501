<script setup>
import ExternalAds from "@/containers/configurations/external-ads";

useHead({
  title: "External Ads",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <ExternalAds />
  </div>
</template>
