<script setup>
import Help from "@/containers/help";
definePageMeta({
  layout: "dashboard",
  middleware: "auth",
});

useHead({
  title: "Bantuan",
});
</script>
<template>
  <Help />
</template>
