<script setup>
import Transaction from "@/containers/report/transaction";

useHead({
  title: "Transaction Report",
});

definePageMeta({
  layout: "dashboard",
  middleware: ["auth", "kyc-check"],
});
</script>
<template>
  <div>
    <Transaction />
  </div>
</template>
