import { useNuxtApp, useRuntimeConfig } from "nuxt/app";
import { defineStore } from "pinia";
import { ref, toRefs } from "vue";

interface Profile {
  id: string;
  kyc_status: string;
  merchant_id: string;
  email: string;
  email_verified_at?: number;
  phone: string;
  phone_verified_at: number;
  full_name: string;
  status: string;
  business_type: string;
  brand_name: string;
  company_name: string;
  flik_comission: number;
  bank_name: string;
  bank_account_number: string;
  bank_account_name: string;
  analytics: {
    conversion_api_access_token: string;
    pixel_id: string;
  };
  logo_url: string;
  website_url: string;
  pic: {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    type: string;
  }[];
}

interface EmailVerification {
  email: string;
  otp: string;
}

interface ISetPassword {
  email: string;
  token: string;
}

export const useMerchantStore = defineStore(
  "merchant",
  () => {
    const profile = ref<Profile>({} as Profile);

    const emailVerification = ref<EmailVerification>({
      email: "",
      otp: "",
    });

    const dataSetPassword = ref<ISetPassword>({} as ISetPassword);

    function $reset() {
      profile.value = {} as Profile;
      emailVerification.value = {
        email: "",
        otp: "",
      };
    }

    function resetSetPassword() {
      dataSetPassword.value = {} as ISetPassword;
    }

    function set(params: any) {
      const myStore = useMerchantStore();
      const states: any = toRefs(myStore.$state);
      const keys = Object.keys(params);
      keys.forEach((key) => {
        states[key].value = params[key];
      });
    }

    const register = (payload: any) => {
      const config = useRuntimeConfig().public;
      return $fetch<any>(`${config.API_URL}/merchant/v2/merchant-user/reg`, {
        method: "POST",
        body: payload,
      });
    };

    const verifyMerchantUserEmail = (otp: string) => {
      const { $api } = useNuxtApp();
      return $api.merchant.verifyMerchantUserEmail({
        otp,
      });
    };

    const refreshMerchantProfile = async () => {
      const { $api } = useNuxtApp();
      const { data } = await $api.merchant.getProfile();
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors[0]);
      }

      set({ profile: data });
    };

    return {
      profile,
      emailVerification,
      dataSetPassword,
      register,
      set,
      $reset,
      verifyMerchantUserEmail,
      refreshMerchantProfile,
      resetSetPassword,
    };
  },
  {
    persist: true,
  },
);
