<script setup>
import Pengirim from "@/containers/manualOrder/pengirim/detail-batch";
useHead({
  title: "Batch Order Pengiriman",
});
definePageMeta({
  layout: "dashboard",
  middleware: ["auth"],
});
</script>
<template>
  <Pengirim />
</template>
