<script setup>
import Pengirim from "@/containers/manualOrder/pengirim/";
useHead({
  title: "Atur Order Pengiriman",
});
definePageMeta({
  layout: "dashboard",
  middleware: ["auth"],
});
</script>
<template>
  <Pengirim />
</template>
