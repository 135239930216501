<script setup>
import { useLandingpageStore } from "~/stores/landingpage";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

definePageMeta({
  middleware: "auth",
  name: "LandingPagePreview",
});
useHead({
  title: `Landing Page Preview`,
});

const landingpage = useLandingpageStore();
</script>
<template>
  <div id="page-preview" class="max-w-4xl mx-auto">
    <OrderFormHeader
      :src="landingpage.getHeaderImg"
      width="200"
      height="100"
      @error="() => landingpage.setHeader('')"
    />
    <div class="p-4 space-y-6 mb-2">
      <div v-for="(item, index) in landingpage.formQueue" :key="index">
        <div v-if="item.type === 'cta_button'" class="mb-4">
          <OrderFormCTAButton
            :label="item.metadata.label"
            is-preview="true"
            :bg-color="item.metadata.background_color"
            :label-color="item.metadata.label_color"
          />
        </div>
        <div v-if="item.type === 'product'">
          <OrderFormProduct
            :product="item.metadata"
            is-preview="true"
            @error="() => (landingpage.formQueue[index].metadata.image = '')"
          />
        </div>
        <div v-if="item.type === 'text'">
          <div v-dompurify-html="item.metadata.text" class="ql-editor"></div>
        </div>
        <div v-if="item.type === 'image'">
          <OrderFormImage
            :src="item.metadata.image"
            is-preview="true"
            @error="() => (landingpage.formQueue[index].metadata.image = '')"
          />
        </div>
        <div v-if="item.type === 'testimony'">
          <OrderFormTestimony
            :items="item.metadata"
            :is-preview="true"
            @error="
              (j) => {
                landingpage.formQueue[index].metadata[j].image = '';
              }
            "
          />
        </div>
      </div>
      <OrderFormOrder
        :fields="landingpage.order_form.metadata.fields"
        :cta-label="landingpage.order_form.metadata.button.cta_label"
        :cta-bg-color="
          landingpage.order_form.metadata.button.cta_background_color
        "
        :cta-label-color="
          landingpage.order_form.metadata.button.cta_label_color
        "
      />
    </div>
  </div>
</template>
<style>
#page-preview .ql-editor h1 {
  @apply text-[2em];
}
#page-preview .ql-editor h2 {
  @apply text-[1.5em];
}
</style>
