<script setup>
import { useNuxtApp } from "#app";
import OTP from "@/containers/auth/otp";

const nuxtApp = useNuxtApp();

nuxtApp.$fb.init();
nuxtApp.$fb.track("PageView");
nuxtApp.$fb.track("CompleteRegistration");

definePageMeta({
  layout: "auth",
  // middleware: ["auth"],
});
</script>
<template>
  <OTP />
</template>
