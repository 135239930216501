<script setup>
import OTP from "@/containers/auth/otp-email";

definePageMeta({
  layout: "auth",
  // middleware: ["auth"],
});
</script>
<template>
  <OTP />
</template>
